
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormDefinitive
} from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption, noData } from "@/utils";
@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        FilterForm: () => import("@/pages/components/forms/FilterFormV2.vue"),
        Echart: () => import("@/components/charts/eChart.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(fpPeriod) {
            fpPeriod = Number(fpPeriod.replace(",", "."));
            if (fpPeriod < 0.95 && fpPeriod > 0.81) return "#e3bb07";
            else if (fpPeriod <= 0.81) return "orange";
            else if (fpPeriod > 1.02) return "red";
            else return "white";
        }
    }
})
export default class Resume extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    showAll = false;
    showFilter = true;
    filterFormPanelShow: boolean | null = true;
    filterForm: FilterFormDefinitive = {
        company: "",
        client: "",
        installation: "",
        elements: [],
        inversors: [],
        dates: [],
        services: [],
        comparative: false,
        days: "10",
        Pmodo: "Precio de Mercado",
        window: "1h",
        valid: true
    };
    CSV = "";
    pricesRef = window.location.origin + `/#/prices`;
    role = Vue.prototype.$user.get().role;
    start = "";
    end = "";
    start1 = "";
    start2 = "";
    end1 = "";
    end2 = "";
    first = false;
    Timer: any[] = [];
    tablePrecCol = [
        {
            text: "Periodo",
            sortable: true,
            value: "element"
        },
        {
            text: "Precio",
            sortable: true,
            value: "precio"
        }
    ];
    tablePrec = [];
    presh = -1;
    tableServCol = [
        {
            text: "Periodo",
            sortable: true,
            value: "element"
        },
        {
            text: "Precio",
            sortable: true,
            value: "precio"
        }
    ];
    tableServ = [];
    priceChart: Chart = { data: [] };
    energy1Chart: Chart = { data: [] };
    energy2Chart: Chart = { data: [] };
    elementPercentChart: Chart = { data: [] };
    heatmapCostChart: Chart = { data: [] };
    heatmapConsumptionChart: Chart = { data: [] };
    energy4Chart: Chart = { data: [] };
    servChart: Chart = { data: [] };
    omieChart: Chart = { data: [] };
    columns = [
        {
            text: "Periodo",
            sortable: true,
            value: "element"
        },
        {
            text: "Precio",
            sortable: true,
            value: "precio"
        }
    ];
    tableTot1 = [];
    period1Chart: Chart = { data: [] };
    period2Chart: Chart = { data: [] };
    errors: ChartError[] = [];
    consolidado: any[] = [];

    // mounted() {
    //     this.getBucketBoundDates();
    // }

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }
    /**
     * Methods
     */
    async fetchData() {
        this.setProgressBar(true);
        this.tablePrecCol = [
            {
                text: "Periodo",
                sortable: true,
                value: "element"
            },
            {
                text: "Precio",
                sortable: true,
                value: "precio"
            }
        ];
        this.tableServCol = [
            {
                text: "Periodo",
                sortable: true,
                value: "element"
            },
            {
                text: "Precio",
                sortable: true,
                value: "precio"
            }
        ];
        this.tableServ = [];
        this.tablePrec = [];
        this.priceChart = { data: [] };
        this.energy1Chart = { data: [] };
        this.energy2Chart = { data: [] };
        this.heatmapConsumptionChart = { data: [] };
        this.heatmapCostChart = { data: [] };
        this.energy4Chart = { data: [] };
        this.period1Chart = { data: [] };
        this.period2Chart = { data: [] };
        this.omieChart = { data: [] };
        this.servChart = { data: [] };
        this.tableTot1 = [];
        this.consolidado = [];
        this.presh = 3;
        this.errors = [];
        this.CSV = "";

        if (this.filterForm.dates) {
            const aux0 = new Date(this.filterForm.dates[0]);
            let aux1;
            if (this.filterForm.dates.length > 1) {
                aux1 = new Date(this.filterForm.dates[1]);
                if (aux1 > aux0) {
                    this.end = this.filterForm.dates[1];
                    this.start = this.filterForm.dates[0];
                } else {
                    this.end = this.filterForm.dates[0];
                    this.start = this.filterForm.dates[1];
                }
            } else {
                this.start = this.filterForm.dates[0];
                this.end = this.start;
            }

            if (this.filterForm.comparative && this.filterForm.days) {
                this.end1 = new Date(
                    new Date(this.start).setDate(
                        new Date(this.start).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.end2 = new Date(
                    new Date(this.end).setDate(
                        new Date(this.end).getDate() +
                            Number(this.filterForm.days)
                    )
                )
                    .toISOString()
                    .split("T")[0];
                this.start1 = new Date(this.start).toISOString().split("T")[0];
                this.start2 = new Date(this.end).toISOString().split("T")[0];
                this.end = this.end2;
                this.start = this.start2;
            }
        }

        do {
            const vacio: any[] = [];
            const JSOON = {
                elements: this.filterForm.elements,
                services: vacio
            };

            if (this.filterForm.services) {
                if (this.filterForm.services.length > 0) {
                    JSOON["services"] = this.filterForm.services;
                }
            }

            try {
                const serviceRes = await this.$api.getPrecServ<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.window,
                    this.filterForm.Pmodo,
                    JSOON
                );
                if (serviceRes.ok) {
                    this.showAll = true;
                    this.priceChart = serviceRes.data.fig;
                    this.CSV = serviceRes.data["CSV"];
                    this.tableServCol = serviceRes.data["Ttot"]["columns"];
                    this.tableServ = serviceRes.data["Ttot"]["values"];
                    this.consolidado = serviceRes.data["consolidado"];
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }

            const energyReq = await this.$api
                .getPrecPers<responseChart>(
                    this.start,
                    this.end,
                    this.filterForm.window,
                    this.filterForm.elements,
                    this.filterForm.Pmodo
                )
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error;
                });
            const promises = [energyReq];
            Promise.allSettled(promises).then(async results => {
                await sleep(500);
                const energyRes = results[0];
                if (energyRes.status === "fulfilled") {
                    if (energyRes.value.ok) {
                        this.showAll = true;
                        // this.CSV = energyRes.value.data.CSV;
                        // this.priceChart = energyRes.value.data.fig2;
                        this.tablePrecCol =
                            energyRes.value.data.tablePrec[1]["columns"];
                        this.tablePrec =
                            energyRes.value.data.tablePrec[1]["values"];
                        this.presh = energyRes.value.data.tablePrec[0];
                        // this.tableServCol =
                        //     energyRes.value.data.tableServ["columns"];
                        // this.tableServ =
                        //     energyRes.value.data.tableServ["values"];
                        this.energy1Chart = energyRes.value.data.fig;
                        this.energy2Chart = energyRes.value.data.fig1;
                        this.period1Chart = energyRes.value.data.figPers;
                        this.period2Chart = energyRes.value.data.figPerE;
                        this.elementPercentChart = energyRes.value.data.fig4;
                        this.heatmapCostChart = energyRes.value.data.figHMS;
                        this.heatmapConsumptionChart = energyRes.value.data.figHMC;
                        this.energy4Chart = energyRes.value.data.fig5;
                        this.omieChart = energyRes.value.data.figOmie;
                        this.Timer = energyRes.value.data.Timer;
                        // this.servChart = energyRes.value.data.fig3;
                    } else {
                        this.errors.push({
                            chart: "Precios-energy1",
                            message: energyRes.value.message
                        });
                        this.showError(energyRes.value.message);
                    }
                }
            });
            if (this.filterForm.comparative && !this.first) {
                this.start = this.start1;
                this.end = this.end1;
                this.first = true;
            } else {
                this.first = false;
            }
        } while (this.first);
        this.setProgressBar(false);
    }

    downloadCSV() {
        // window.location.href =
        //     window.location.origin +
        //     `/api/common/download-csv?box=irizar_e01&element=P1&start=2022-04-05&end=2022-04-06`;

        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(this.CSV);
        anchor.target = "_blank";
        anchor.download =
            this.filterForm.client.split(" - ")[1] +
            "_Costes_" +
            this.start +
            "_" +
            this.end +
            ".csv";
        anchor.click();
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    checkChartErrors(chart: string) {
        const error = this.errors.filter(error => error.chart === chart);
        if (error.length && error[0].chart === chart) {
            this.showError(error[0].message);
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    getEchart(option) {
        if (option == 0) {
            return 0;
        }
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        } else if (option.series) {
            if (option.series.length) {
                return { ...Eoption, ...option };
            }
        }
        return noData;
    }
}
